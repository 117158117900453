import axios from "axios";
import config from "../../constants/config";


const userLoginAPI = async (email,password) => {
    console.log(email,password)
  const url = config.API_URL + "/user/adminLogin" 
  return await axios.post(url, {
    email: email,
    password: password,
    fcmToken: ""
  })
}

export const verifySecurityPinAPI = async (data, token) => {
  const url = config.API_URL + "/user/verifySecurityPin"
  return await axios.post(url, data, {
    headers: {
      Authorization: token,
    },
  })
}


export default userLoginAPI