import React, { useContext, useEffect, useState } from "react";
import "./RInput.css";
import { UserDataProvider } from "../../Pages/Users/UserInLineEditView/UserInLineEditView";
import { formatDate } from "../Layout/NavBars/NavBars";
const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z$/;
function RInput({
  lable,
  placeholder,
  type,
  value,
  disabled = false,
  keyName,
  userUpdateType = "user",
}) {

    const IsDate = () => {
        if (dateRegex.test(value)) {
            return formatDate(value);
        }
        return value;
    };
  const { setUpdatedData, updatedData } = useContext(UserDataProvider);
  const [isBlur, setIsBlur] = useState(false);
  const [inputValue, setInputValue] = useState(
    updatedData?.updates?.[keyName] ?? IsDate()
  );
  useEffect(() => {
    // console.log(keyName==="firstName" && Object.keys(updatedData?.updates ?? {}).includes(keyName),updatedData)
    if (Object.keys(updatedData?.updates ?? {}).includes(keyName)) {
      setIsBlur(true);
    } else {
      setIsBlur(false);
    }
  },[]);
  return (
    <div>
      <p className="inputLableSty">{lable}</p>
      <input
        style={{ cursor: disabled ? "no-drop" : "" }}
        className={"RInputSty " + (isBlur ? " isBlur" : "")}
        disabled={disabled}
        value={inputValue}
        placeholder={placeholder ?? "N/A"}
        type={type ?? "text"}
        onChange={(e) => {
          // setValue(e.target.value)
          setIsBlur(true);
          setInputValue(e.target.value);
          setUpdatedData((prev) => ({
            ...prev,
            status: true,
            type: userUpdateType,
            updates: { ...prev.updates, [keyName]: e.target.value },
          }));
        }}
      />
    </div>
  );
}

export default RInput;
