import React, { useState } from 'react';

function LeagueInput({value,lable,disabled=false,placeholder,type,setInput}) {

    const [isBlur,setIsBlur]=useState(false);
    const [inputValue,setInputValue]=useState(value ?? "");
    return (
        <div>
           <p className='inputLableSty'>{lable}</p>
           <input style={{cursor:disabled ? "no-drop":""}} className={'RInputSty ' + (isBlur ? " isBlur":"") } disabled={disabled} value={inputValue} placeholder={placeholder ??  "N/A"} type={type ?? "text"}  onChange={(e)=>{
            // setValue(e.target.value)
            setIsBlur(true);
            setInputValue(e.target.value);
            setInput(e.target.value);
            }}/>
        </div>
    );
}

export default LeagueInput;