import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { UserProfileCom } from '../UserInLineEditView';
import RInput from '../../../../components/RInput';

function UserReferral(props) {
    return (
        <Row>
        <Col xs={2}>
          <UserProfileCom />
        </Col>
        <Col xs={10}>
          <div>
            <div>
              <h1 className="sideHeadingSty">Referrals</h1>
              <div>
                <Row className="input-row">
                  <Col xs={6}>
                    <RInput lable={"Current Tier"} disabled={true}/>
                  </Col>
                  <Col xs={6}>
                    <RInput lable={"Total Referrals"} disabled={true}/>
                  </Col>
                </Row>
              </div>
            </div>
            <h1 className="sideHeadingSty">Referral Program Info</h1>
            <div>
              <Row className="input-row">
                <Col xs={6}>
                  <RInput lable={"Total Referrals Credited to User"} disabled={true} />
                </Col>
                <Col xs={6}>
                  <RInput lable={"User’s Unique Referral Code"} disabled={true} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
}

export default UserReferral;