import React from 'react';
import "./Archive.css"
import Layout from '../../components/Layout/Layout';
function Archive(props) {
    return (
        <Layout>
        <div>
            Archive
        </div>
        </Layout>
    );
}

export default Archive;