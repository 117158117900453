import React from 'react';
import PropTypes from 'prop-types';
import "./Rbutton.css"
const RButton = ({
  disabled,
  onClick,
  title,
  isLoading,
  style,
  colors = ['#EF4B4B', '#EF4B4B88'],
  gradientStyle,
  textStyle,
  disabledColors = ['#8A8A8F', '#8A8A8F88'],
}) => {
  function getGradientColors() {
    return disabled ? disabledColors : colors;
  }

  const buttonStyle = {
    height: '36px',
    width: '100%',
    borderRadius: '4px',
    border: 'none',
    outline: 'none',
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `linear-gradient(${getGradientColors().join(',')})`,
    ...style,
  };

  return (
    <button
      style={buttonStyle}
      disabled={disabled}
      onClick={onClick}
      type="submit"
      className="rbutton"
    >
      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <span style={{ color: '#FFFFFF', ...textStyle }}>
          {title}
        </span>
      )}
    </button>
  );
};

RButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  colors: PropTypes.arrayOf(PropTypes.string),
  gradientStyle: PropTypes.object,
  textStyle: PropTypes.object,
  disabledColors: PropTypes.arrayOf(PropTypes.string),
};

export default RButton;
