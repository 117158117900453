import React, { useEffect, useRef, useState } from 'react';
import "./LeagueConfig.css"
import Layout from '../../components/Layout/Layout';
import { IC_MLB, IC_MLS, IC_NBA, IC_NCAAB, IC_NCAAF, IC_NFL, IC_NHL, IC_NTA, IC_PGA } from '../../constants/images';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import { Switch, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { activateLeague, createLeague, deleteSingleLeague, getAllLeagues, getLeagues, updateLeague } from '../../API/LeagueAPI/LeaguesApi';
import axios from 'axios';
import RInput from '../../components/RInput';
import LeagueInput from '../../components/RInput/LeagueInput';



function LeagueConfig(props) {
  const [view, setView] = useState('list');
  const [league, setLeague] = useState([]);
  const [activeLeagues, setActiveLeagues] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [base64,setBase64]=useState();
  const [leageName,setLeageName]=useState("");
  const [leageStatus,setLeageStatus]=useState(true);
  const [isSaving,setIsSaving]=useState(false);
  const [refresh,setRefresh]=useState(true);
  const [loading,setIsLoading]=useState(false);
  const getIconName = (leagueName) => {
    switch (leagueName) {
      case 'MLB': return IC_MLB;
      case 'NFL': return IC_NFL;
      case 'NHL': return IC_NHL;
      case 'NBA': return IC_NBA;
      case 'MLS': return IC_MLS;
      case 'NCAAB': return IC_NCAAB;
      case 'NCAAF': return IC_NCAAF;
      case 'NTA': return IC_NTA;
      case 'PGA': return IC_PGA;
      default: return IC_NBA;
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      console.log('Token retrieved:', token);
      setIsLoading(true);
      getLeagues(token)
        .then((res) => {
          console.log(res.data);
          setLeague(res?.data);
          setActiveLeagues(res.data);
        })
        .catch(err => console.log(err)).finally(()=>{
          setIsLoading(false);
        });
    } else {
      console.log("Token Not Found");
      // Redirect to login page or display a message
    }
  }, [refresh]);

 

  const leagues = ['MLB', 'NFL', 'NHL', 'NBA', 'MLS', 'NCAAB', 'NCAAF', 'NTA', 'PGA'];

  const isLeagueActive = (leagueName) => {
    //console.log("CheckingLeagueNAme",leagueName,activeLeagues)
    return activeLeagues.some(league => league.name === leagueName);
  };

  const handleSwitchChange = (leagueName) => {
    const isActive = isLeagueActive(leagueName);

    // Toggle active status
    const updatedActiveLeagues = isActive
    ? activeLeagues.filter(league => league.name !== leagueName.name) // Remove if already active (deactivate)
    : [...activeLeagues, {  name: leagueName.name }];               // Add if not active (activate)

    setActiveLeagues(updatedActiveLeagues); // Update local state

    if (!isActive) {
      // If not active (switch turned on), make a POST request to activate
      const token = sessionStorage.getItem('token');
      if (token) {
        console.log('Token retrieved:', token,leagueName?._id,leagueName?.isActive);
    activateLeague(token,leagueName?._id,leagueName?.isActive).then((res)=>{
      console.log(res.data)
    }).catch(err =>console.log(err))}
    } else {
      // If active (switch turned off), make a DELETE request to deactivate (delete from DB)
      const token = sessionStorage.getItem('token');
      if (token) {
        console.log('Token retrieved:', token,leagueName?._id,leagueName?.isActive);
    activateLeague(token,leagueName?._id,leagueName?.isActive).then((res)=>{
      console.log(res.data)
    }).catch(err =>console.log(err))}
    }
  };
  const handleshowModalClose = () =>{ setShowModal(false);}
  const saveLeagueData=()=>{
    if(leageName && base64){
      const token = sessionStorage.getItem("token");
      setIsSaving(true);
      createLeague(token,leageName,base64,leageStatus).then(()=>{
        setShowModal(false);
        setRefresh(res=>!res);

      }).catch((err)=>{
          console.log(err);
          alert("League not created, please try again!");
      }).finally(()=>{
        setIsSaving(false);
      })
    }
    else{
      alert("please fill all the fields");
    }
  }
  const handleShowModal = () =>{ setShowModal(true);console.log("first")}

  const handleCreateModalClose = () =>{ setShowCreateModal(false);}

  const handleShowCreateModal = (leagueName) =>{ setShowCreateModal(true);}

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(reader.result);
        setBase64(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please select an image file.');
    }
  };
  return (

    <Layout>
      
      <div style={{display:"flex",justifyContent:"center",marginTop:"3%"}}>
          <Button variant='primary' onClick={()=>{handleShowModal()}}>Add new</Button>
        </div>

      <div style={{ display: 'flex', flexDirection: 'row',marginTop:"5%"}}>
      <Modal show={showModal} onHide={handleshowModalClose}>
        <Modal.Header >
          <Modal.Title>League Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <LeagueInput lable={"League Name"} setInput={setLeageName}/>
        <p className='inputLableSty'>League Status</p>
          <div style={{display:"flex",justifyContent:"center"}}>
            <Button variant='outline-primary' active={leageStatus} onClick={()=>{setLeageStatus(true)}}>
                true
            </Button>
            <Button  variant='outline-primary' style={{marginLeft:"2%"}} active={!leageStatus} onClick={()=>{setLeageStatus(false)}}>
                false
            </Button>
          </div>
          <p className='inputLableSty'>League Icon</p>
          <div>
          <Form.Group controlId="formFile" className="mb-3" >
        <Form.Label>Default file input example</Form.Label>
        <Form.Control type="file" accept='image/*' onChange={handleFileChange}/>
        <Image
                  src={base64}
                  className="League-imgs"
                  style={{ marginRight: '10px', marginLeft: '10px' }} 
                />
      </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleshowModalClose} disabled={isSaving}>
            Close
          </Button>
          <Button variant="primary" onClick={saveLeagueData} disabled={isSaving}>
            {isSaving ? "Sending..." :"Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>




        <div style={{ justifyContent: 'center', display: 'flex', height: '98vh', alignItems: 'flex-start', width: '50%' }}>
       
          <div className='Logo-cons'>
            { loading ? <Spinner />: league.map((leagueName, index) => (
              <LeageDataCard imageUrl={leagueName?.imageUrl} name={leagueName?.name} index={index+1} isActives={leagueName?.isActive} leagueId={leagueName?._id} dataRefresh={setRefresh}/>
            ))}
          </div>
        </div>
        
        <div style={{ justifyContent: 'center', display: 'flex', height: '98vh', alignItems: 'flex-start', width: '50%' }}>
          <div className='Logo-cons'>
            <h4>Active Leagues</h4>
            {league.filter((item)=>(item?.isActive)).map((leaguess, index) => (
              <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'calc(100% / 3)', margin: '10%' }}>
                <Image
                  src={leaguess?.imageUrl}
                  className="League-imgs"
                  style={{ marginRight: '5%', marginLeft: '5%' }} 
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default LeagueConfig;



const LeageDataCard=({index,imageUrl,name,isActives,leagueId,dataRefresh})=>{
  const [IsActive,setIsActive]=useState(isActives);
  const [isActivating,setIsActivating]=useState(false);
  const token = sessionStorage.getItem("token");
  const [isDeleting,setIsDeleting]=useState(false);
  const [base64,setBase64]=useState(imageUrl);
  const [leageName,setLeageName]=useState(name ?? "");
  const [leageStatus,setLeageStatus]=useState(isActives);
  const [isSaving,setIsSaving]=useState(false);
  const [showModal, setShowModal] = useState(false);
  const activateLeagueData=()=>{
  
    setIsActivating(true)
    activateLeague(token,leagueId,!IsActive).then((res)=>{
      console.log(res);
      dataRefresh(prev=>!prev);
      setIsActive(pre=>(!pre))
    }).catch((err)=>{
        console.log(err)
      
    }).finally(()=>{
      setIsActivating(false);
    })
  }
  const deleteLeague=()=>{
    let person = prompt("Do you want to delete this "+name+" league, Please enter league name") ?? "";
    if(person.toLowerCase()===name.toLowerCase()){
      setIsDeleting(true)
      deleteSingleLeague(token,leagueId).then(()=>{
        dataRefresh(prev=>!prev);
      }).catch((err)=>{
        console.log(err)
      })
      .finally(()=>{
        setIsDeleting(false)
      })
    }else{
      alert(person + " name is not correct");
    }
    
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(reader.result);
        setBase64(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please select an image file.');
    }
  };

  const updateLeagueData=()=>{
    if(leageName && base64){
      const token = sessionStorage.getItem("token");
      setIsSaving(true);
      updateLeague(token,leageName,base64,leageStatus,leagueId).then(()=>{
        
        dataRefresh(prev=>!prev);
        setShowModal(false);

      }).catch((err)=>{
          console.log(err);
          alert("League not created, please try again!");
      }).finally(()=>{
        setIsSaving(false);
      })
    }
    else{
      alert("please fill all the fields");
    }
  }
return(
  <tr>
      <Modal show={showModal}>
        <Modal.Header >
          <Modal.Title>League Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <LeagueInput lable={"League Name"} setInput={setLeageName} value={leageName}/>
        <p className='inputLableSty'>League Status</p>
          <div style={{display:"flex",justifyContent:"center"}}>
            <Button variant='outline-primary' active={leageStatus} onClick={()=>{setLeageStatus(true)}}>
                true
            </Button>
            <Button  variant='outline-primary' style={{marginLeft:"2%"}} active={!leageStatus} onClick={()=>{setLeageStatus(false)}}>
                false
            </Button>
          </div>
          <p className='inputLableSty'>League Icon</p>
          <div>
          <Form.Group controlId="formFile" className="mb-3" >
        <Form.Label>Default file input example</Form.Label>
        <Form.Control type="file" accept='image/*' onChange={handleFileChange}/>
        <Image
                  src={base64}
                  className="League-imgs"
                  style={{ marginRight: '10px', marginLeft: '10px' }} 
                />
      </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setShowModal(false)}} disabled={isSaving}>
            Close
          </Button>
          <Button variant="primary" onClick={updateLeagueData} disabled={isSaving}>
            {isSaving ? "Sending..." :"Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
  {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'calc(100% / 3)', margin: '10%' }}> */}
  <td>
  <p style={{ alignItems: 'center', justifyContent: 'center' }}>{index}</p>
  </td>
  <td>
  <Image
    src={imageUrl}
    className="League-imgs"
    style={{ marginRight: '10px', marginLeft: '10px' }} 
  />
  </td>
  <td>
  <h5 style={{ marginRight: '20%', marginLeft: '10%' }}>{name}</h5>
  </td>
  <td >
  <div style={{ display:"flex",justifyContent: 'center', width: '50px', marginLeft: '10%' }}>
    {
      isActivating ? <Spinner size='sm' /> : <Switch
      checked={IsActive} // Set switch state
      onChange={() => activateLeagueData()} // Handle state change
      inputProps={{ 'aria-label': 'controlled' }}
      disabled={isDeleting}
    />
    }
  </div>
  </td>
  <td>
  <Button variant='info' onClick={()=>{setShowModal(true)}} disabled={isActivating || isDeleting}>Update</Button>
  </td>
  <td>
  <Button variant='danger' onClick={()=>{deleteLeague()}}  disabled={isActivating || isDeleting}>{isDeleting ? "Deleting..." : "Delete"} </Button>
  </td>
  <div>

  </div>
{/* </div> */}
</tr>
)
}