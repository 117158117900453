import React, { useEffect, useState } from "react";
import "./LoginPage.css";
import { LOGO_FULL_RED } from "../../constants/images";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import userLoginAPI, { verifySecurityPinAPI } from "../../API/Login/LoginApi";
import RButton from "../../components/RButton";
import { useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";
import { COL_PRIMARY } from "../../constants/colors";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Please enter the E-mail ID")
    .email("Please enter a valid E-mail ID"),
  password: Yup.string().required("Please enter the password"),
});

function LoginPage(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const navigation = useNavigate();
  const [token, setToken] = useState("");
  const handleChange = (event, field) => {
    console.log(`${field} changed to ${event.target.value}`);
  };
  const [pin, setPin] = useState(false);
  const [pinValue, setPinValue] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      navigation("/DashBoard");
    } else {
      navigation("/");
    }
  }, []);
  const handleVerifySecurityPin = () => {
    verifySecurityPinAPI(
      {
        securityPin: pinValue,
      },
      token
    )
      .then(async (res) => {
        console.log("PIN VERIFICATION RESPONSE", res.data);
        console.log("NO EXCEPTION");
        // await AsyncStorage.setItem('isPinVerified', JSON.stringify(true))
        // setPinVerified();
        // login();
        sessionStorage.setItem("token", token);
        navigation("/DashBoard");
      })
      .catch((e) => {
        console.log("EXCEPTION HAS OCCURED");
        console.log(e);
        // console.log(e?.response?.data);
        setApiError(e.response.data.error1 + " " + e.response.data.error2);
        // setApiError();
        // if (e.response.data.locked) {
        //   setIsBottomSheetOpen(true);
        // }
      })
      .finally(() => {
        // setIsSubmitting(false);
      });
  };

  return (
    <div
      style={{ height: "100vh", backgroundColor: "black", paddingTop: "3%" }}
    >
      <div style={{}}>
        <div
          style={{
            width: "100%",
            height: "10%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={LOGO_FULL_RED}
            style={{ width: "9%", height: "10%" }}
            alt=""
          />
        </div>
        <div style={{ width: "100%", marginTop: "2%", marginBottom: "2%" }}>
          <h2 style={{ color: "white", textAlign: "center" }}>
            Sign in
          </h2>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          userLoginAPI(values.email, values.password)
            .then((response) => {
              console.log("Login successful", response);
              setPin(response?.data?.loginSecurityPin);
              setToken(response.data.token);
              if (!response?.data?.loginSecurityPin) {
                sessionStorage.setItem("token", response.data.token);
                navigation("/DashBoard");
              }
            })
            .catch((e) => {
              if (e?.response?.data?.message) {
                setApiError(e?.response?.data?.message);
              } else if (e?.response?.data?.locked) {
                setApiError(
                  `Too many failed login attempts! Your account has been locked. Please reset your password, or reach out to \nsupport@rabbetgaming.com for further assistance`
                );
              } else if (e?.response?.data?.failedLoginAttempts) {
                setApiError(
                  `Remaining login attempts: ${
                    5 - e?.response?.data?.failedLoginAttempts
                  }`
                );
              }

              console.log(e);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <Form>
              <div className="login-con">
                <div
                  style={{
                    width: "35vw",
                    backgroundColor: "#3535355d",
                    border: 1,
                    borderStyle: "solid",
                    borderColor: "#ffffff65",
                    padding: 50,
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {!pin ? (
                      <>
                        <div style={{ marginBottom: "3vh" }}>
                          {/* <label htmlFor="email" style={{color:"white"}}>Email</label> */}
                          <Field
                            className="fieldInput"
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            onChange={(e) => {
                              handleChange(e);
                              handleChange(e, "email");
                            }}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="errorMsg"
                          />
                        </div>

                        <div style={{ marginBottom: "5vh" }}>
                          {/* <label htmlFor="password" style={{color:"white"}}>Password</label> */}
                          <Field
                            className="fieldInput"
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            onChange={(e) => {
                              handleChange(e);
                              handleChange(e, "password");
                            }}
                            autocomplete="off"
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                        <div
                          style={{
                            marginBottom: 24,
                            width: "100%",
                            marginTop: 8,
                          }}
                        >
                          <RButton
                            title="Submit"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}
                            // onClick={handleSubmit}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                         
                        <PinInput
                          length={4}
                          initialValue=""
                          secret
                          secretDelay={100}
                          onChange={(value, index) => {
                            setPinValue(value);
                          }}
                          type="numeric"
                          inputMode="number"
                          style={{ padding: "10px", marginBottom: "4vh",display:"flex",justifyContent:"center" }}
                          inputStyle={{
                            borderColor: COL_PRIMARY,
                            color: "#ffffff",
                            fontSize: "16px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                          inputFocusStyle={{ borderColor: "#ffffff" }}
                          onComplete={(value, index) => {}}
                          autoSelect={true}
                          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        />
                        
                        <div
                          style={{
                            marginBottom: 24,
                            width: "100%",
                            marginTop: 8,
                          }}
                        >
                          <RButton
                            title="Submit"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}
                            onClick={handleVerifySecurityPin}
                          />
                        </div>
                        
                      </>
                    )}

                    <div
                      style={{ marginBottom: 16, color: "white", marginTop: 5 }}
                    >
                      {/* Forgot Password? */}
                    </div>

                    {apiError && <div className="errorMsg">{apiError}</div>}
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}

export default LoginPage;
