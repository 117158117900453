import './App.css';
import Root from './Routes/Root';

function App() {
  return (
   <Root />
  );
}

export default App;
