import axios from "axios";
import config from "../../constants/config";


const url = config.API_URL + "/admin/shopCoins/";
const url1 = config.API_URL + "/game/shopCoins/";


export const getShopData = async (token) => {
    console.log('called getShopData Method');

    return await axios.get(url1, {
        headers: {
            Authorization: token, // Correct placement of Authorization header
            'Content-Type': 'application/json', // Ensure content type is set to JSON
        }
    });
};

export const createShopData = async (token, coins, amount, discount,iconType,bundleAmount) => {
    console.log('called createShopData Method',coins, amount, discount,iconType,bundleAmount);

    return await axios.post(url, {
        totalCoins: coins,
        amount: amount,
        discount: discount,
        iconType: iconType,
        bundleAmount : bundleAmount
    }, {
        headers: {
            Authorization: token, // Correct placement of Authorization header
            'Content-Type': 'application/json', // Ensure content type is set to JSON
        }
    });
};

export const updateShopData = async (token, id, amount, coins, discount,icon,bundleAmount) => {
    console.log('called updateShopData Method',icon);

    return await axios.put(url, {
        id: id,
        updatedFields: {
            totalCoins: coins,
            amount: amount,
            discount: discount,
            iconType:icon,
            bundleAmount : bundleAmount
        }
    }, {
        headers: {
            Authorization: token, // Correct placement of Authorization header
            'Content-Type': 'application/json', // Ensure content type is set to JSON
        }
    });
};

export const deleteShopData = async (token, Id) => {
    console.log('called deleteShopData Method',Id);

    return await axios.delete(url, {
        params: {
            id: Id
        },
        headers: {
            Authorization: token, // Correct placement of Authorization header
            'Content-Type': 'application/json', // Ensure content type is set to JSON
        }
    });
};