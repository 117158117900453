import React, { useEffect, useState } from 'react';
import "./EmailSettings.css"
import Layout from '../../components/Layout/Layout';
import RInput from '../../components/RInput';
import { UserDataProvider } from '../Users/UserInLineEditView/UserInLineEditView';
import { Button, Col, Row } from 'react-bootstrap';
import { getEmailInfo } from '../../API/EmailAPI/EmailAPI';
import JsonView from 'react18-json-view';
import LeagueInput from '../../components/RInput/LeagueInput';
function EmailSettings(props) {
    const [updatedData,setUpdatedData]=useState({});
    const [loading,setLoading]=useState(false);
  const [emailData,setEmailData]=useState({});
    useEffect(()=>{
      const token = sessionStorage.getItem('token');
      setLoading(false);
      getEmailInfo(token).then((res)=>{
        setEmailData(res.data?.data)
      })
      .catch((err)=>{
console.log(err);
      }).finally(()=>{
        setLoading(true);
      })
    },[])
    return (
        <UserDataProvider.Provider value={{setUpdatedData}}>
        <Layout>
           <div style={{margin:"0 3vw",marginTop:"10vh"}}>
            <h1 className="sideHeadingSty">SMTP Credentials </h1>
            {
              loading &&   <div>
              <Row className="input-row" style={{marginBottom:"5vh"}}>
                <Col xs={6}>
                <LeagueInput lable={"SMTP Host"} value={emailData?.MailHost}  userUpdateType='user' keyName={"Server"}/>
                </Col>
                <Col xs={6}>
                <LeagueInput  lable={"SMTP Port "} value={emailData?.MailPort} keyName={"Port"}/>
                </Col>
              </Row>
              <Row className="input-row">
                <Col xs={6}>
                <LeagueInput lable={"SMTP Username "} value={emailData?.MailUser} keyName={"Username"}/>
                </Col>
                <Col xs={6}>
                <LeagueInput  lable={"SMTP Password "} value={emailData?.MailPassword} keyName={"Password"}/>
                </Col>
              </Row>
            </div>
            }
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"5vh"}}>
              <Button > 
                Save Settings
              </Button>
            </div>
              </div>
              {/* <JsonView src={emailData}/> */}
        </Layout>
        </UserDataProvider.Provider>
    );
}

export default EmailSettings;