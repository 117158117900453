import axios from "axios";
import config from "../../constants/config";

export const getEmailInfo = async (token) => {
    console.log('called create Promo Method');
    const url1 = config.API_URL + "/admin/mailConfig"
    return await axios.get(url1, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      }
    });
  };