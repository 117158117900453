import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RiEditCircleFill } from "react-icons/ri";
import { IoMdAddCircle } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { getAllUsersData } from "../../../API/UsersAPI/UsersApi";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import { saveAs } from "file-saver";
import Papa from "papaparse";
const recordCountSelecter = [10, 15, 20, 25, 30];

function UsersTableView() {
  const navigator = useNavigate();
  const [usersData, setUsersData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchBtnClicked,setSearchBtnClicked]=useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [recordCount, setRecordCount] = useState(recordCountSelecter[0]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    console.log(token);
    setLoading(true);
    
      getAllUsersData(token,pageCount,recordCount,searchQuery)
        .then((res) => {
          if (typeof res.data === "object") {
            setUsersData(res.data.map((item)=>{
                return {Actions:item._id,...item}
            }));
            
          }

          // alert(typeof res.data)
          console.log("data fetched", res);
          // alert("users data fetched");
          setLoading(false);
        })
        .catch((error) => {
          // alert("users data fetched" + error);
          console.log("error");
          setLoading(false);
          setUsersData([])
        });
    
  }, [pageCount,recordCount,searchBtnClicked]);


  const handleCSVExport = () => {
    try {
      // Convert JSON to CSV
      const csv = Papa.unparse(usersData);

      // Create a Blob object for the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Use file-saver to save the file
      saveAs(blob, "data.csv");
    } catch (err) {
      console.error("Error converting JSON to CSV:", err);
    }
  };
  const CustomCellComponent = ({ value }) => {
    // console.log(value)
    return (
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          onClick={() => {
            navigator("/Users/UserInLineEditView/" + value);
          }}
        >
          <RiEditCircleFill
            style={{ color: "#EEB50C", fontSize: "1.4vw", cursor: "pointer" }}
          />
        </div>
        <div>
          <IoMdAddCircle
            style={{ color: "#70AA2C", fontSize: "1.4vw", cursor: "pointer" }}
          />
        </div>
        <div>
          <FaUser
            style={{ color: "#1814F3", fontSize: "1.4vw", cursor: "pointer" }}
          />
        </div>
        <div>
          <TiDelete
            style={{ color: "#EF4B4B", fontSize: "1.6vw", cursor: "pointer" }}
          />
        </div>
      </div>
    );
  };

  const getColumns = () => {
    const filteredEntries = Object.entries(
      Object.assign({}, ...usersData)
    ).filter(
      ([key, value]) =>
        typeof value !== "function" &&
        typeof value !== "object" &&
        typeof value !== "symbol" &&
        typeof value !== "undefined"
    );
    const filteredObject = Object.fromEntries(filteredEntries);
    const dataKeys = Object.keys(filteredObject);
    return dataKeys.map((item, index) => {
      return index === 0
        ? {
            field: item,
            headerName: item,
            align: "center",
            headerAlign: "center",
            sortable: false,
            editable: false,
            renderCell: (params) => {
              return <CustomCellComponent value={params.value} />;
            },
            width: 150,
          }
        : {
            field: item,
            headerName: item,
            align: "center",
            headerAlign: "center",
            sortable: false,
            editable: true,
          };
    });
  };

  const columns: GridColDef[] = [...getColumns()];

  const rows = usersData.map((item, index) => {
    const filteredEntries = Object.entries(item ?? {}).filter(
      ([key, value]) =>
        typeof value !== "function" &&
        typeof value !== "object" &&
        typeof value !== "symbol" &&
        typeof value !== "undefined"
    );
    const filteredObject = Object.fromEntries(filteredEntries);
    return { ...filteredObject, id: index };   
  });
  return (
    <div>
        <div>
                <div style={{marginTop:10,display:"flex",justifyContent:"space-between",padding:"0% 3.5%",marginBottom:"2%"}}>
                    <div>   
                        <div>
                        <span className="db-collection-path">
                            DB Collection Path:/52.170.92.147:27017/rabbet.users
                        </span>
                        </div>
                        <div>
                        <span className="last-updated">
                            <span className='db-collection-path'>Last Updated: </span>DD/MM/YYYY at HH:MM AM/PM
                        </span>
                        </div>
                        <div>
                        <span className="last-updated">
                        <span className='db-collection-path'>Last Modified By: </span>DD/MM/YYYY at HH:MM AM/PM
                        </span>
                        </div>
                    </div>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <Button id="button-addon2" style={{backgroundColor:"#EEB50C",color:"white",border:"none"}} >
                         Save Changes
                        </Button>  
                        <Button id="button-addon2" style={{backgroundColor:"#0079FF",color:"white",border:"none",marginLeft:10}} onClick={()=>{handleCSVExport()}}>
                          Export CSV
                        </Button>  
                    </div>
                </div>
        
            </div>
      <div className="">
        <InputGroup className="mb-3 px-5">
          <DropdownButton
            // variant="outline-primary"
            title="Select a view"
            id="input-group-dropdown-1"
            className="DropdownButton-con"
          >
            <Dropdown.Item href="#">Action</Dropdown.Item>
            <Dropdown.Item href="#">Another action</Dropdown.Item>
            <Dropdown.Item href="#">Something else here</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="#">Separated link</Dropdown.Item>
          </DropdownButton>

          <Form.Control aria-label="Text input with dropdown button" onChange={(event)=>{setSearchQuery(event.target.value)}} value={searchQuery}/>
          <Button
            variant="outline-secondary"
            id="button-addon2"
            style={{
              backgroundColor: "#0079FF",
              color: "white",
              padding: "13px 35px",
            }}
            disabled={loading}
            onClick={()=>{
              setSearchBtnClicked(!searchBtnClicked);
            }}
          >
            Search
          </Button>
        </InputGroup>
      </div>
      <div style={{ margin: "0vw 2.9vw" }}>
        <Paper sx={{ height: "70vh", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                // paginationModel: { pageSize: recordCount },
                meta: { hasNextPage: true },
              },
            }}
            pageSizeOptions={[5, 10, 15]}
            paginationMode="client"
            pagination
            // estimatedRowCount={500}
            // hideFooterSelectedRowCount={true}
            rowSpacingType="border"
            // autoPageSize
            hideFooterPagination
            hideFooter
            rowCount={1000}
            onPaginationMetaChange={() => {
              alert("changed");
            }}
            // checkboxSelection
            sx={{ border: 0 }}
            loading={loading}
            onProcessRowUpdateError={(error) => {
              alert("on Process Row Update Error");
            }}
            disableRowSelectionOnClick
            onPaginationModelChange={(model) => {
              // setPaginationModel(model);
              // console.log(model);
              // getMoreData();
            }}
            onRowDoubleClick={(item) => {
              alert(item?.row?._id);
            }}
            // autosizeOptions={{
            //     columns: ['dob', 'status', 'createdBy'],
            //     includeOutliers: false,
            //     includeHeaders: false,
            //   }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "1%",
              paddingRight:"2%"
            }}
          >
            <div
              style={{
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  // border: "1px solid black",
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ margin: "0", color: "black", margin: "0 0.5vw" }}>
                  Row per page
                </p>
              </div>

              <div
                style={{
                  // border: "1px solid black",
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Form.Select size="sm" onChange={(event)=>{setRecordCount(event.target.value)}}   disabled={loading}>
                  {recordCountSelecter.map((value, index) => {
                    return <option key={index + "values"}>{value}</option>;
                  })}
                </Form.Select>

                <p
                  style={{ margin: "0", color: "black", margin: "0 0.5vw" }}
                ></p>
              </div>
              <Button
                onClick={() => {
                  
                  setPageCount((page) => page - 1);
                }}
                disabled={pageCount <= 1 || loading}
              >
                <HiOutlineArrowSmLeft />
              </Button>

              <div
                style={{
                  // border: "1px solid black",
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ margin: "0", color: "black", margin: "0 0.5vw" }}>
                  {pageCount}
                </p>
              </div>

              <Button
                onClick={() => {
                  // getMoreData();
                  setPageCount((page) => page + 1);
                }}
                disabled={loading}
              >
                <HiOutlineArrowSmRight />
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default UsersTableView;
