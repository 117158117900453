import React, { useEffect, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import { getConfigData, postConfigData } from '../../../API/ConfigSettings/ConfigSettingsAPI';
import "./ConfigSettings.css"
import JsonView from 'react18-json-view';
function ConfigSettings(props) {
    const [configData, setConfigData] = useState({}); 
    const [editedGlobal, setEditedGlobal] = useState({});
    const [editedClaimDaily, setEditedClaimDaily] = useState({});
    const [editedEmailSettings, setEditedEmailSettings] = useState({});
    const [editedReferralTiers, setEditedReferralTiers] = useState({});
    const [editedStandardUser, setStandardUser] = useState({});
    const [loading,setLoading]=useState(true);
    const [loadingState, setLoadingState] = useState({
        global: false,
        claimDaily: false,
        emailSettings: false,
        referralTiers: false,
        standardUser:false
    });
    const token = sessionStorage.getItem("token");
    const [refresh,setRefresh]=useState(false)
    useEffect(() => {
        setLoading(true);
        getConfigData(token)
            .then((data) => {
                setConfigData(data.data); 
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching config data:", error);
            });
    }, [refresh,token]); 

    const handleInputChange = (section, key, value) => {
        switch (section) {
            case 'global':
                setEditedGlobal(prevState => ({ ...prevState, [key]: value }));
                break;
            case 'claimDaily':
                setEditedClaimDaily(prevState => ({ ...prevState, [key]: value }));
                break;
            case 'emailSettings':
                setEditedEmailSettings(prevState => ({ ...prevState, [key]: value }));
                break;
            case 'referralTiers':
                setEditedReferralTiers(prevState => ({ ...prevState, [key]: value }));
                break;
            case 'standardUser':
                setStandardUser(prevState => ({ ...prevState, [key]: value }));
                    break;
            default:
                break;
        }
    };

    const updateConfigData = async (section) => {
        setLoadingState(prev => ({ ...prev, [section]: true }));
        let updatedValues = {};

        if (section === 'referralTiers') {
            updatedValues.referralTiers = configData.referralTiers.map((item, index) => ({
                ...item,
                rabbetCoins: editedReferralTiers[`[${index}].rabbetCoins`] !== undefined ? editedReferralTiers[`[${index}].rabbetCoins`] : item.rabbetCoins,
                rabbetKarats: editedReferralTiers[`[${index}].rabbetKarats`] !== undefined ? editedReferralTiers[`[${index}].rabbetKarats`] : item.rabbetKarats,
            }));
        } else if (section === 'emailSettings') {
            updatedValues.emailSettings = { ...configData.emailSettings, ...editedEmailSettings };
        } else if (section === 'claimDaily') {
            updatedValues.claimDaily = { ...configData.claimDaily, ...editedClaimDaily };
        }
        else if (section === 'standardUser') {
            updatedValues.standardUser = { ...configData.standardUser, ...editedStandardUser };
        } else {
            updatedValues = { ...configData, ...editedGlobal };
        }

        try {
            const res = await postConfigData(token, configData._id, { ...updatedValues });
            console.log(res.data);
            // Clear edited states for the updated section
            if (section === 'referralTiers') setEditedReferralTiers({});
            else if (section === 'emailSettings') setEditedEmailSettings({});
            else if (section === 'claimDaily') setEditedClaimDaily({});
            else if (section === 'standardUser') setStandardUser({});
            else setEditedGlobal({});
            setRefresh(prev=>!prev)
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingState(prev => ({ ...prev, [section]: false }));
        }
    };

    const renderObject = (obj, section) => {
        if (!obj || typeof obj !== 'object') return null;

        return Object.entries(obj).map(([key, value]) => {
            const displayValue = editedEmailSettings[key] !== undefined ? editedEmailSettings[key] : value;

            if (typeof value === 'object' && !Array.isArray(value)) {
                return (
                    <React.Fragment key={key}>
                        <tr className="object-key">
                            <td colSpan="2">{key}</td>
                        </tr>
                        {renderObject(value, `${section}.${key}`)}
                    </React.Fragment>
                );
            } else if (Array.isArray(value)) {
                return (
                    <React.Fragment key={key}>
                        <tr className="object-key">
                            <td colSpan="2">{key}</td>
                        </tr>
                        {value.map((item, index) => (
                            <tr key={index} className="array-item">
                                <td>{`${key} [${index}]`}</td>
                                <td>
                                    <input
                                        style={{width:"100%",border:"0px"}}
                                        type="text"
                                        value={editedReferralTiers[`[${index}].rabbetCoins`] !== undefined ? editedReferralTiers[`[${index}].rabbetCoins`] : item.rabbetCoins}
                                        onChange={(e) => handleInputChange('referralTiers', `[${index}].rabbetCoins`, e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                    style={{width:"100%",border:"0px"}}
                                        type="text"
                                        value={editedReferralTiers[`[${index}].rabbetKarats`] !== undefined ? editedReferralTiers[`[${index}].rabbetKarats`] : item.rabbetKarats}
                                        onChange={(e) => handleInputChange('referralTiers', `[${index}].rabbetKarats`, e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </React.Fragment>
                );
            } else {
                return (
                    <tr key={key}>
                        <td>{key}</td>
                        <td>
                            <input
                            style={{width:"100%",border:"0px"}}
                                type="text"
                                value={displayValue}
                                onChange={(e) => handleInputChange(section, key, e.target.value)}
                            />
                        </td>
                    </tr>
                );
            }
        });
    };

    return (
        <>
        {loading ? <Spinner /> :    <div style={{ margin: "0 5vw" }}>
            {/* Global Keys Table */}
            <h3>Global Configuration</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(configData ?? {})
                        .filter(([key]) => !['claimDaily', 'emailSettings', 'referralTiers', '_id','standardUser'].includes(key))
                        .map(([key, value]) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>
                                    <input
                                    style={{width:"100%",border:"0px"}}
                                        type="text"
                                        value={editedGlobal[key] !== undefined ? editedGlobal[key] : value}
                                        onChange={(e) => handleInputChange('global', key, e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <Button variant='warning' disabled={loadingState.global} onClick={() => updateConfigData('global')}>
                {loadingState.global ? "Saving..." : "Save Global Config"}
            </Button>
            <h3>Standard User</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                {Object.entries(configData?.standardUser ?? {})
                        .map(([key, value]) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>
                                    <input
                                    style={{width:"100%",border:"0px"}}
                                        type="text"
                                        value={editedStandardUser[key] !== undefined ? editedStandardUser[key] : value}
                                        onChange={(e) => handleInputChange('standardUser', key, e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            {/* <JsonView src={editedStandardUser}/> */}
            <Button variant='warning' disabled={loadingState.standardUser} onClick={() => updateConfigData('standardUser')}>
                {loadingState.standardUser ? "Saving..." : "Save Global Config"}
            </Button>
            {/* Claim Daily Table */}
            <h3>Claim Daily</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                {Object.entries(configData?.claimDaily ?? {})
                        .map(([key, value]) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>
                                    <input
                                    style={{width:"100%",border:"0px"}}
                                        type="text"
                                        value={editedClaimDaily[key] !== undefined ? editedClaimDaily[key] : value}
                                        onChange={(e) => handleInputChange('claimDaily', key, e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                    {/* {renderObject(configData.claimDaily, 'claimDaily')} */}
                </tbody>
            </Table>
            <Button variant='warning' disabled={loadingState.claimDaily} onClick={() => updateConfigData('claimDaily')}>
                {loadingState.claimDaily ? "Saving..." : "Save Claim Daily"}
            </Button>
            {/* Email Settings Table */}
            <h3>Email Settings</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {renderObject(configData?.emailSettings, 'emailSettings')}
                </tbody>
            </Table>
            <Button variant='warning' disabled={loadingState.emailSettings} onClick={() => updateConfigData('emailSettings')}>
                {loadingState.emailSettings ? "Saving..." : "Save Email Settings"}
            </Button>

            {/* Referral Tiers Table */}
            <h3>Referral Tiers</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Tier</th>
                        <th>Rabbet Coins</th>
                        <th>Rabbet Karats</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(configData?.referralTiers) && configData?.referralTiers.length > 0 ? (
                        configData?.referralTiers.map((item, index) => (
                            <tr key={index}>
                                <td>{`Tier ${item.tier}`}</td>
                                <td>
                                    <input
                                        type="text"
                                        value={editedReferralTiers[`[${index}].rabbetCoins`] !== undefined ? editedReferralTiers[`[${index}].rabbetCoins`] : item.rabbetCoins}
                                        onChange={(e) => handleInputChange('referralTiers', `[${index}].rabbetCoins`, e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={editedReferralTiers[`[${index}].rabbetKarats`] !== undefined ? editedReferralTiers[`[${index}].rabbetKarats`] : item.rabbetKarats}
                                        onChange={(e) => handleInputChange('referralTiers', `[${index}].rabbetKarats`, e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3">No referral tiers available.</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Button variant='warning' disabled={loadingState.referralTiers} onClick={() => updateConfigData('referralTiers')}>
                {loadingState.referralTiers ? "Saving..." : "Save Referral Tiers"}
            </Button>
        </div>}
        </>
     
    );
};



export default ConfigSettings;