import axios from "axios";
import config from "../../constants/config";

const url = config.API_URL + '/game/allLeaguesNames';
const url1 = config.API_URL + '/admin/leagueNames';
const url2 = config.API_URL + '/admin/activeLeague';
const url3=config.API_URL +"/admin/league/";

export const getAllLeagues = async (token) => {
    console.log('called getAllLeagues Method');

    return await axios.get(url, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      }
    });
  };

  export const createLeague = async (token,leagueName,imageUrl,isActive) => {
    console.log('called createLeague Method');

    return await axios.post(url1,
      {
        name:leagueName,
        imageUrl: imageUrl,
        isActive: isActive
    }, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      }
    });
  };

  export const getLeagues = async (token) => {
    console.log('called getLeagues Method');

    return await axios.get(url1, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      }
    });
  };


  export const updateLeague = async (token,leagueName,imageUrl,isActive,leagueID) => {
    console.log('called updateLeague Method');

    return await axios.put(url1,
      {
        id:leagueID,
        updateData:{
          imageUrl: imageUrl,
          name:leagueName,
          isActive: isActive,
        }
      
    }, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      }
    });
  };

  export const activateLeague = async (token,leagueId,isActive) => {
    console.log('called activateLeague Method',leagueId,isActive);

    return await axios.put(url2,
      {
        leagueId: leagueId,
        isActive: isActive
    }, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      }
    });
  };


  export const deleteSingleLeague = async (token,leagueId) => {
    console.log('called deleteSingleLeague Method',leagueId);

    return await axios.delete(url3+leagueId,
       {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      }
    });
  };