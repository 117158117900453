import React from "react";
import NavBars from "./NavBars/NavBars";
import "./Layout.css";

function Layout({ children }) {
  console.log("layout rendring....")
  return (
    <div>
      <div className="Layout-Row">
        <div style={{ width: "18vw" }}>
          <div className="Nav-cont">
            <NavBars />
          </div>
        </div>
        <div className="Body-cont">{children}</div>
      </div>
    </div>
  );
}

export default Layout;
