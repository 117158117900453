import React, { useState } from 'react';
import "./DBSettings.css"
import Layout from '../../components/Layout/Layout';
import { Box, Tab, Tabs } from '@mui/material';
import { CustomTabPanel } from '../Users/UserInLineEditView/UserInLineEditView';
import AllReports from '../Reports/AllReports/AllReports';
import PromotionReport from '../Reports/PromotionReports/PromotionReport';
import ShopConfig from './ShopCoins/ShopConfig';
import ConfigSettings from './ConfigSettings/ConfigSettings';
function DBSettings(props) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          "aria-controls": `simple-tabpanel-${index}`,
        };
      }
    return (
        <Layout>
        <div style={{ margin: "0vw 2.9vw" ,marginTop:"2vh"}}>
    <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
    <Tabs
      value={value}
      onChange={handleChange}
      //   centered
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      textColor="primary"
      indicatorColor="primary"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Tab
        label="Config Settings"
        {...a11yProps(0)}
        style={{ fontWeight: "700" }}
      />
      <Tab
        label="Shop Coins"
        {...a11yProps(1)}
        style={{ fontWeight: "700" }}
      />
      <Tab
        label="---"
        {...a11yProps(2)}
        style={{ fontWeight: "700" }}
      />
      <Tab
        label="---"
        {...a11yProps(3)}
        style={{ fontWeight: "700" }}
      />
      <Tab
        label="---"
        {...a11yProps(4)}
        style={{ fontWeight: "700" }}
      />
      <Tab
        label="---"
        {...a11yProps(5)}
        style={{ fontWeight: "700" }}
      />
      <Tab
        label="---"
        {...a11yProps(6)}
        style={{ fontWeight: "700" }}
      />
    </Tabs>
  </Box>

  <CustomTabPanel value={value} index={0}>
                 <ConfigSettings/>
   </CustomTabPanel>
   <CustomTabPanel value={value} index={1}>
   <ShopConfig/>
    </CustomTabPanel>
    <CustomTabPanel value={value} index={2}>
    2
    </CustomTabPanel>
    <CustomTabPanel value={value} index={3}>
    3
    </CustomTabPanel>
    <CustomTabPanel value={value} index={4}>
    4
    </CustomTabPanel>
    <CustomTabPanel value={value} index={5}>
    5
    </CustomTabPanel>
    <CustomTabPanel value={value} index={6}>
    6
    </CustomTabPanel>
  </div>
  
  </Layout>
    );
}

export default DBSettings;