import axios from "axios";
import config from "../../constants/config";

export const getAllUsersData = async (token,page,limit,searchQuery) => {
    console.log('called create Promo Method');
    const url1 = config.API_URL + "/admin/users"
    return await axios.get(url1, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      },
      params:{
        page,limit,searchQuery
      }
    });
  };

export const getSearchUsersData = async (token,) => {
    console.log('called create Promo Method');
    const url1 = config.API_URL + "/admin/users"
    return await axios.get(url1, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      },
    });
  };


export const getUserData = async (token,id) => {
    console.log('called getUserData Method');
    const users = config.API_URL + "/admin/users/"+id;
    return await axios.get(users, {
      headers: {
        Authorization: token, // Correct placement of Authorization header
        'Content-Type': 'application/json', // Ensure content type is set to JSON
      },
    });
  };

export const getUserWalletData = async (token,userID,page,limit,period,startDate,endDate) => {
  console.log('called getUserWalletData Method');
  const users = config.API_URL + "/admin/user/wallet/transactions";
  return await axios.get(users, {
    headers: {
      Authorization: token, // Correct placement of Authorization header
      'Content-Type': 'application/json', // Ensure content type is set to JSON
    },
    params:{
      userID, page,limit,period,startDate,endDate
    }
  });
};


export const getUserPromotionalOffersData = async (token,userID,page,limit,period,startDate,endDate) => {
  console.log('called getUserPromotionalOffersData Method');
  const users = config.API_URL + "/admin/user/promotionalOffers";
  return await axios.get(users, {
    headers: {
      Authorization: token, // Correct placement of Authorization header
      'Content-Type': 'application/json', // Ensure content type is set to JSON
    },
    params:{
      userID, page,limit,period,startDate,endDate
    }
  });
};


export const updateUser = async (token,data) => {
  console.log('called updateUser Method');
  const users = config.API_URL + "/admin/user/edit";
  return await axios.put(users,data, {
    headers: {
      Authorization: token, // Correct placement of Authorization header
      'Content-Type': 'application/json', // Ensure content type is set to JSON
    },

  });
};