import React, { useEffect, useState } from 'react';
import './ShopConfig.css';
import { createShopData, deleteShopData, getShopData, updateShopData } from '../../../API/ShopCoinsAPI/ShopCoinsApi';
import { Button, Modal, Table } from 'react-bootstrap';
import LeagueInput from '../../../components/RInput/LeagueInput';
import { Backdrop, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

function ShopConfig() {
    const [loading, setLoading] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [shopData, setShopData] = useState([]);
    const [isDeleting,setIsDeleting]=useState(false);
    const [showAdModal, setShowAdModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [amount, setAmount] = useState(0);
    const [coins, setCoins] = useState(0);
    const [bundleAmount,setBundleAmount] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [icon,setIcon] = useState(false);
    const [isSaving] = useState(false);
    const [refresh, setRefresh] = useState(true)

    useEffect(() => {
        const token = sessionStorage.getItem("token");
        setLoading(true);
        getShopData(token).then((res) => {
            console.log(res?.data);
            setShopData(res?.data);
            setLoading(false);
        }).catch(err => console.log(err))
    }, [refresh])
    const handleShowModal = (card = null) => {
        if (card) {
            console.log(card, "CardDetails")
            // Editing existing ad
            setSelectedCard(card);
            setAmount(card.amount);
            setCoins(card.totalCoins);
            setIcon(card.icon);
            setDiscount(card.discount);
            setIsEditMode(true);
        } else {
            // Creating new ad
            setSelectedCard(card);
            setAmount(0);
            setCoins(0);
            setDiscount(0);
            setIcon(false);
            setIsEditMode(false); // Set mode to creating
        }
        setShowAdModal(true); // Show the modal
    };

    const handleshowModalClose = () => { setShowAdModal(false); }

    const addCoins = () => {
        const token = sessionStorage.getItem("token");
        console.log(icon)
        createShopData(token, coins, amount,discount,icon,bundleAmount)
            .then((res) => {
                setShowAdModal(false);
                setRefresh(res => !res);
                console.log(res?.data);
            }).catch((err) => {
                console.log(err);
                //setShowAdModal(false);
                alert("Shop data not created, please try again!");
            })
    };

    const updateCoins = () => {
        const token = sessionStorage.getItem("token");
       updateShopData(token,selectedCard?._id, amount, coins, discount,icon,bundleAmount)
            .then((res) => {
                setShowAdModal(false);
                setRefresh(res => !res);
                console.log(res?.data);
            }).catch((err) => {
                console.log(err);
                //setShowAdModal(false);
                alert("Shop data not updated, please try again!");
            })
    }
    const deleteCoins = (item) => {
        const token = sessionStorage.getItem("token");
        setIsDeleting(true)
       deleteShopData(token,item?._id)
            .then((res) => {
                setShowAdModal(false);
                setRefresh(res => !res);
                console.log(res?.data);
            }).catch((err) => {
                console.log(err);
                //setShowAdModal(false);
                alert("Shop data not deleted, please try again!");
                setIsDeleting(false)
            }).finally(
                setIsDeleting(false)
            )
    }

    return (
        <>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" /> </Backdrop>
            <Modal show={showAdModal} onHide={handleshowModalClose}>
                <Modal.Header >
                    <Modal.Title>{isEditMode ? "Update Coins" : "Add Coins"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LeagueInput type="number" lable={"Coins"} value={coins} setInput={setCoins} />
                    <LeagueInput type="number" lable={"Amount"} value={amount} setInput={setAmount} />
                    <LeagueInput type="number" lable={"Bundle Amount"} value={bundleAmount} setInput={setBundleAmount} />
                    <LeagueInput type="number" lable={"Discount"} value={discount} setInput={setDiscount} />
                    <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Icon</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={icon}
                    onChange={(e) => setIcon(e.target.value === "true")} 
                    >
                        <FormControlLabel value={true} control={<Radio />} label="true" />
                        <FormControlLabel value={false} control={<Radio />} label="false" />
                    </RadioGroup>
                    </FormControl>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleshowModalClose} disabled={isSaving}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={isEditMode ? updateCoins : addCoins} disabled={isSaving}>
                        {isEditMode ? (isSaving ? "Updating..." : "Update") : (isSaving ? "Saving..." : "Save Changes")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <div style={{ margin: "0vw 2.9vw", marginTop: "2vh" }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', margin: '5 0 5 0' }}>
                    <p>Shop config</p>
                    <Button onClick={() => { handleShowModal() }} variant="primary" style={{ justifyContent: 'center', alignItems: 'center' }}>Add Coins</Button>
                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Total Coins</th>
                            <th>Amount</th>
                            <th>Bundle Amount</th>
                            <th>Icon</th>
                            <th>Discount</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shopData.map((item, index) => (
                            <ShopDataRow key={index} item={item} deleteCoins ={deleteCoins} handleShowModal={handleShowModal} isDeleting={isDeleting} />
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ShopConfig


const ShopDataRow = ({ isDeleting,item, handleShowModal,deleteCoins }) => {
    return (
        <tr>
            <td>{item?.totalCoins}</td>
            <td>{item?.amount}</td>
            <td>{item?.bundleAmount ?? 0}</td>
            <td>{item?.iconType ? 'true' : 'false'}</td>
            <td>{item?.discount}</td>
            <td style={{justifyContent:'space-around',display:'flex'}}>
                <Button onClick={() => handleShowModal(item)} variant="primary">Update</Button>
                <Button  onClick={()=>{deleteCoins(item)}} disabled={isDeleting} variant="danger">{isDeleting ? "Deleting..." : "Delete"}</Button>
            </td>
        </tr>
    );
};

