import { Backdrop, Box, CircularProgress, Tab, Tabs } from "@mui/material";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import UserAccountInfo from "./UserAccountInfo/UserAccountInfo";
import UserSecurityStatus from "./UserSecurityStatus/UserSecurityStatus";
import UserPromoHistory from "./UserPromoHistory/UserPromoHistory";
import UserWalletTransactions from "./UserWalletTransactions/UserWalletTransactions";
import UserReferral from "./UserReferral/UserReferral";
import UserSubscriptionManagement from "./UserSubscriptionManagement/UserSubscriptionManagement";
import UserSettings from "./UserSettings/UserSettings";
import { Button, Col, Dropdown, Image, Row, Spinner } from "react-bootstrap";
import { LOGO_ICON_WHITE } from "../../../constants/images";
import "./UserInLineEditView.css";
import { getUserData, updateUser } from "../../../API/UsersAPI/UsersApi";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { RiEditCircleFill } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import UserReports from "./UserReports/UserReports";
export const UserDataProvider = createContext();

function UserInLineEditView(props) {
  const [value, setValue] = useState(0);
  const location = useLocation();
  const userId = location.pathname.split("/")[3];
  const [userData, setUserData] = useState({});
  const [loading,setIsloading]=useState(false);
  const [updatedData,setUpdatedData]=useState({});
  const [reloadUserData,setReloadUserData]=useState(false);
  const [saveLoading,setSaveLoading]=useState(false);
  // console.log("data:", userId);
  const updateUserData=()=>{
    console.log(updatedData);
    setSaveLoading(true);
    
    const token = sessionStorage.getItem("token");
    updateUser(token,updatedData).then((res)=>{
      console.log(res);
    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      setReloadUserData(!reloadUserData);
      setUpdatedData({status:false,type:"user",updates:{},userId:userId})
      setSaveLoading(false);
    })
    
  }
  useEffect(() => {
    // alert(id)
    setUpdatedData({status:false,type:"user",updates:{},userId:userId})
    setIsloading(true)
    const token = sessionStorage.getItem("token");
    // setUserData(dummyUserData);
    getUserData(token,userId)
      .then((res) => {
        console.log(res.data);
        if (typeof res.data === "object") {
        setUserData(res.data);
        }
        setIsloading(false)
      })
      .catch((err) => {
        setIsloading(false)
        console.log(err);
        setUserData({})
      });
  }, [reloadUserData]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleCSVExport = () => {
    try {
      // Convert JSON to CSV
      const csv = Papa.unparse([userData]);

      // Create a Blob object for the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Use file-saver to save the file
      saveAs(blob, "data.csv");
    } catch (err) {
      console.error("Error converting JSON to CSV:", err);
    }
  };
  return (
    <UserDataProvider.Provider value={{ userData, setUserData,updatedData,setUpdatedData ,setReloadUserData,userId}}>

      <div style={{ margin: "0vw 2.9vw" }}>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" /> </Backdrop>
        {/* <Outlet /> */}
        <div style={{position:"sticky",top:0,backgroundColor:"white"}}>
        <div>
                <div style={{marginTop:10,display:"flex",justifyContent:"space-between",padding:"0% 3.5%",marginBottom:"2%"}}>
                    <div>   
                        <div>
                        <span className="db-collection-path">
                            DB Collection Path:/52.170.92.147:27017/rabbet.users
                        </span>
                        </div>
                        <div>
                        <span className="last-updated">
                            <span className='db-collection-path'>Last Updated: </span>DD/MM/YYYY at HH:MM AM/PM
                        </span>
                        </div>
                        <div>
                        <span className="last-updated">
                        <span className='db-collection-path'>Last Modified By: </span>DD/MM/YYYY at HH:MM AM/PM
                        </span>
                        </div>
                    </div>
                    <div style={{display:"flex",alignItems:"center"}}>
                      {updatedData?.status && <Button disabled={saveLoading} id="button-addon2" style={{backgroundColor:"#EEB50C",color:"white",border:"none"}} onClick={()=>{
                        updateUserData();
                      }} >
                        
                       {saveLoading ? <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          /> :"Save Changes" }  
                        </Button>  }
                        
                        <Button id="button-addon2" style={{backgroundColor:"#0079FF",color:"white",border:"none",marginLeft:10}} onClick={handleCSVExport}>
                          Export CSV
                        </Button>  
                    </div>
                </div>
        
            </div>
        <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            //   centered
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            textColor="primary"
            indicatorColor="primary"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tab
              label="Account Info"
              {...a11yProps(0)}
              style={{ fontWeight: "700" }}
            />
            <Tab
              label="Security & Status"
              {...a11yProps(1)}
              style={{ fontWeight: "700" }}
            />
            <Tab
              label="Promotion HQ"
              {...a11yProps(2)}
              style={{ fontWeight: "700" }}
            />
            <Tab
              label="Wallet"
              {...a11yProps(3)}
              style={{ fontWeight: "700" }}
            />
            <Tab
              label="Referrals"
              {...a11yProps(4)}
              style={{ fontWeight: "700" }}
            />
            <Tab
              label="Subscription"
              {...a11yProps(5)}
              style={{ fontWeight: "700" }}
            />
            <Tab
              label="Settings"
              {...a11yProps(6)}
              style={{ fontWeight: "700" }}
            />
            <Tab
              label="Reports"
              {...a11yProps(7)}
              style={{ fontWeight: "700" }}
            />
          </Tabs>
        </Box>
        </div>
            {
              !loading && <Row style={{ marginTop: "2vh" }}>
              {value < 2 ? (
                <>
                  <Col xs={2}>
                    <UserProfileCom />
                  </Col>
                  <Col xs={10}>
                    <div>
                      <CustomTabPanel value={value} index={0}>
                        <UserAccountInfo />
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={1}>
                        <UserSecurityStatus />
                      </CustomTabPanel>
                    </div>
                  </Col>
                  <div style={{paddingBottom:"20vh"}} />
                </>
              ) : (
                <>
                  <CustomTabPanel value={value} index={2}>
                    <UserPromoHistory />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <UserWalletTransactions />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={4}>
                    <UserReferral />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={5}>
                    <UserSubscriptionManagement />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={6}>
                    <UserSettings />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={7}>
                    <UserReports />
                  </CustomTabPanel>
                </>
              )}
            </Row>
            }
        
      </div>
     
    </UserDataProvider.Provider>
  );
}

export default UserInLineEditView;

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const UserProfileCom = () => {
  const { userData,userId ,setReloadUserData} = useContext(UserDataProvider);
  const [dropdown,setDropdown]=useState(userData?.userType?.[0] ?? "")
  const inputRef = useRef(null);
  const [UpdateLoading,setUpdateLoading]=useState(false);
  const [tempImage,setTempImage]=useState(userData?.profilePicture ?? LOGO_ICON_WHITE);
  const [imageHasSet,setImageHasSet]=useState(false);
  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(reader.result);
        setTempImage(reader.result);
      setImageHasSet(true);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please select an image file.');
    }
  };

  return (
    <div className="Nav-hdr">
      <div style={{width:"100%",justifyContent:"space-between",display:"flex"}}>
        <div>
          {
            imageHasSet && <> <Button style={{backgroundColor:"#8a0000"}} onClick={()=>{
              setTempImage(userData?.profilePicture ?? LOGO_ICON_WHITE);
              setImageHasSet(false);
            }}>
           <IoClose style={{fontSize:"1.5vw",alignSelf:"flex-end",cursor:"pointer"}}/>
          </Button>
         
          </>
          }
           
        </div>
        <div> {
            imageHasSet &&
        <Button style={{backgroundColor:"#008a00"}} >
            <FaCheckCircle style={{fontSize:"1.5vw",alignSelf:"flex-end",cursor:"pointer"}} onClick={()=>{
                setUpdateLoading(true)
                setImageHasSet(false);
                    const token = sessionStorage.getItem("token");
                   updateUser(token,{status:false,type:"user",updates:{profilePicture:tempImage},userId:userId}).then((res)=>{
                    console.log(res);
                  }).catch((err)=>{
                    console.log(err);
                    setImageHasSet(true);
                  }).finally(()=>{
                    setReloadUserData((prev)=>(!prev));
                    setUpdateLoading(false);
                    
                  })
                }}/>
          </Button>}
        </div>
        <input
        type="file"
        accept="image/*"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      {
        UpdateLoading ? <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        style={{fontSize:"2vw",color:"blue",alignSelf:"flex-end",marginRight:"10%",cursor:"pointer"}}
      /> : <RiEditCircleFill onClick={handleClick} style={{fontSize:"2vw",color:"blue",alignSelf:"flex-end",marginRight:"10%",cursor:"pointer"}}/>
      }
      
      </div>
      <div className="user-img-con" style={{marginTop:"2%"}}>
        <Image
          src={tempImage}
          alt="User Profile"
          className="user-img"
          rounded
        />
       
      </div>
 
 
      <div className="name-con">
        <p className="user-name">{userData?.username}</p>
        <p className="user-date">{userData?.firstName}</p>
        <p className="user-date">{userData?.lastName}</p>
      </div>
      <div >
        <div>
      <Dropdown onSelect={(value)=>{setDropdown(value);setImageHasSet(false);
                    const token = sessionStorage.getItem("token");
                   updateUser(token,{status:false,type:"user",updates:{userType:value},userId:userId}).then((res)=>{
                    console.log(res);
                  }).catch((err)=>{
                    console.log(err);
                  }).finally(()=>{
                    setReloadUserData((prev)=>(!prev));
                    setUpdateLoading(false);
                    
                  })}} >
      <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" style={{borderRadius:20,marginTop:"2vh",}}>
        {dropdown}
      </Dropdown.Toggle>

      <Dropdown.Menu >
        <Dropdown.Item eventKey={"WAGERUSER"}>WAGERUSER</Dropdown.Item>
        <Dropdown.Item eventKey={"ADMIN"}>ADMIN</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </div>
    </div>
    </div>
  );
};
